@charset "UTF-8";

@use './settings/variable' as v;
@use './settings/mixin' as r;
@use './settings/_path' as p;

.nk23_c-footer {
	@include r.reset;
}

#wrapper .nk23_c-footer {
	background-color: #fff;
	position: relative;

	&.is-fixed {
		width: 100%;
		left: 0;
		bottom: 0;
		position: fixed;
	}

	// .nk23_c-footer .nk23_l-container .
	.nk23_l-container {
		max-width: 1280px;
		padding: 0 35px;

		@include r.pc {
			display: flex;
			flex-wrap: wrap;
		}

		@include r.sp {
			padding: 0 25px;
		}
	}

	// .nk23_c-footer__title .
	&__title {
		margin-bottom: 31px;
		font-weight: 700;
		display: flex;
		align-items: center;
		font-size: 18px;
		line-height: 27px;

		// .nk23_c-footer__title img .
		img {
			margin-right: 8px;
			min-width: 20px;
		}

		// .nk23_c-footer__title + .nk23_c-footer__menu.
		& + .nk23_c-footer__menu {
			margin-top: -18px;
		}
	}

	// .nk23_c-footer__col .
	&__col {
		width: 25%;
		padding-right: 15px;

		@include r.pc {
			padding-bottom: 18px;
		}

		// .nk23_c-footer__col:not(:first-child) .
		&:not(:first-child) {
			border-left: 1px solid rgba($color: v.$color46, $alpha: 0.25);
			padding-left: 15px;
		}

		&:nth-child(1) {
			padding-left: 40px;
		}

		&:last-child {
			padding-right: 40px;
		}
	}

	// .nk23_c-footer__menu .
	&__menu {
		padding-left: 16px;
		margin-top: 16px;

		&.is-style1 {
			.nk23_c-footer__menu {
				&__item {
					// .nk23_c-footer__menu.is-style1 .nk23_c-footer__menu__item:not(:last-child) .
					&:not(:last-child) {
						margin-bottom: 4px;
					}
				}
			}
		}

		&.is-style2 {
			.nk23_c-footer__menu {
				&__item {
					// .nk23_c-footer__menu.is-style2 .nk23_c-footer__menu__item:not(:last-child) .
					&:not(:last-child) {
						margin-bottom: 3px;
					}
				}
			}
		}

		& + .nk23_c-footer__title {
			margin-top: 30px;
		}

		&__item {
			// .nk23_c-footer__menu__item:not(:last-child) .
			&:not(:last-child) {
				margin-bottom: 8px;
			}
		}

		&list {
			font-size: 14px;
			line-height: 20px;
			color: #fff;

			// nk23_c-footer__menulist__text .
			&__text {
				font-weight: 700;
			}

			&__item {
				// .nk23_c-footer__menulist__item:not(:last-child) .
				&:not(:last-child) {
					margin-bottom: 24px;
				}
			}

			a {
				@include r.hover {
					text-decoration: underline;
					opacity: 1;
				}
			}
		}

		// .nk23_c-footer__menu__text .
		&__text {
			padding-left: 10px;
			text-indent: -10px;
			font-size: 14px;
			line-height: 20px;

			@include r.hover {
				text-decoration: underline;
				opacity: 1;
			}

			&.is-icon {
				// .nk23_c-footer__menu__text.is-icon:after .
				&:after {
					margin-left: 8px;
					top: 4px;
					position: relative;
					display: inline-block;
					content: '';
					width: 20px;
					height: 20px;
					background: url(p.$path + 'images/common/icon/icon_horse-blue.svg')
						no-repeat center / 20px auto;
				}
			}
		}

		// .nk23_c-footer__menusub .
		&sub {
			padding-left: 13px;
			margin-left: 2px;
			margin-top: 8px;
			color: rgba($color: #fff, $alpha: 0.5);
			border-left: 1px solid rgba($color: #fff, $alpha: 0.5);

			&__item {
				// .nk23_c-footer__menusub__item:not(:last-child) .
				&:not(:last-child) {
					margin-bottom: 8px;
				}
			}
		}

		&list__text,
		&__text,
		&sub__text {
			display: inline-block;
		}

		// .nk23_c-footer__menuchild .
		&child {
			margin-top: 4px;
			padding-left: 16px;
			font-size: 12px;
			line-height: 18px;

			@include r.pc {
				margin-bottom: -4px;
			}

			// nk23_c-footer__menuchild__text .
			&__text {
				padding-left: 10px;
				text-indent: -10px;

				@include r.hover {
					text-decoration: underline;
					opacity: 1;
				}
			}

			&__item {
				// .nk23_c-footer__menuchild__item:not(:last-child) .
				&:not(:last-child) {
					margin-bottom: 4px;
				}
			}

			&.is-style1 {
				.nk23_c-footer__menuchild__text {
					color: #fff;
				}
			}
		}
	}

	// .nk23_c-footer__menubot .
	&__menubot {
		font-size: 12px;
		line-height: 18px;
		display: flex;
		align-items: center;

		@include r.pc {
			padding-right: 20px;
			margin-right: auto;
		}

		@include r.sp {
			margin-bottom: 8px;
			line-height: 22px;
			color: #fff;
		}

		&__item {
			// .nk23_c-footer__menubot__item:not(:last-child) .
			&:not(:last-child) {
				padding-right: 10px;
				margin-right: 10px;
				border-right: 1px solid v.$color19;

				@include r.sp {
					padding-right: 16px;
					margin-right: 16px;
				}
			}
		}

		&__link {
			@include r.hover {
				// text-decoration: underline;
				opacity: 0.5;
			}
		}
	}

	&__copyright {
		font-size: 12px;
		line-height: 18px;

		@include r.sp {
			color: #fff;
		}
	}

	// .nk23_c-footer__top .
	&__top {
		padding: 40px 0 38px;
		background-color: v.$color0;
		color: #fff;

		> .nk23_l-container {
			@include r.pc {
				padding: 0;
			}
		}
	}

	// .nk23_c-footer__bottom .
	&__bottom {
		padding: 33px 45px 35px;

		@include r.sp {
			padding: 18px 0 54px;
			background-color: v.$color0;
			display: flex;
			justify-content: center;
		}

		& > .nk23_l-container {
			@include r.sp {
				width: max-content;
			}
		}
	}

	&__totop {
		opacity: 0;
		visibility: hidden;
		position: fixed;
		right: 40px;
		width: 56px;
		height: 56px;
		border-radius: 50%;
		background: #fff url(p.$path + 'images/common/icon/icon_totop.svg')
			no-repeat center / 22px auto;
		@include r.shadow(v.$color23);
		@include r.border(v.$color23);
		z-index: 997;
		transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;

		&.is-show {
			opacity: 1;
			visibility: visible;
		}

		@include r.hover {
			opacity: 1;
			box-shadow: none;
			transform: translateY(2px);
		}

		@include r.sp {
			bottom: 10px;
			right: 16px;
			width: 40px;
			height: 40px;
			background-size: 10px;
		}

		&.is-absolute {
			right: 40px;

			@include r.sp {
				position: absolute;
				right: 16px;
				top: -50px;
				bottom: auto;
			}
		}
	}

	.is-disable {
		pointer-events: none !important;
	}
}
